import { Uuid } from '@edgebox/data-definition-kit';
import { Permission } from './shared-permissions';

export type SystemFeatureConfig = Record<string, any>;
export type AllowedPublicApiPermission =
  | Permission.CONTENT_READ
  | Permission.CONTENT_TYPE_READ
  | Permission.ASSET_READ_FILE
  | Permission.EXTERNAL_LINK_READ
  | Permission.SPACE_READ;
export type AllowedPublicApiTypes = 'live-graphql' | 'live-rest' | 'cdn-graphql' | 'cdn-rest' | 'cdn-assets';
export type PublicApiPermissionConfig = Record<AllowedPublicApiTypes, AllowedPublicApiPermission[]>;

export interface SystemProperty {
  entryCreatedAt?: string | null;
  firstPublishedAt?: string | null;
  versionCreatedAt?: string | null;
  publishedAt?: string | null;

  customId?: string | null;
  id?: string | null;
  uuid?: string | null;

  isPublished?: boolean | null;

  entryVersion?: number | null;
  localizationVersion?: number | null;

  name?: string | null;
  description?: string | null;
}
const SYSTEM_PROPERTY_SUB_QUERY = `
sys {
  entryCreatedAt
  firstPublishedAt
  versionCreatedAt
  publishedAt

  customId
  id
  uuid

  isPublished

  entryVersion
  localizationVersion

  name
  description
}
`;

export interface SystemOrganization {
  sys: SystemProperty;
  uuid: string;
  id: string;
  name: string;
}
export interface SystemOrganizationEntryQueryVariables {
  organizationUuid: string;
}
const ORGANIZATION_ENTRY_QUERY_PROPERTIES = `
  ${SYSTEM_PROPERTY_SUB_QUERY}

  id
  uuid
  name
`;
const ORGANIZATION_ENTRY_QUERY = `
query getOrganization($organizationUuid: String!) {
  result: organization(uuid: $organizationUuid) {
    ${ORGANIZATION_ENTRY_QUERY_PROPERTIES}
  }
}
`;

export interface AddSystemOrganizationInput {
  id?: string | null;
  customId?: string | null;
  uuid: string;
  name: string;
  description?: string | null;
  isPublished?: boolean | null;
  featureConfig?: SystemFeatureConfig | null;
}
interface AddSystemOrganizationMutationVariables {
  organizationDefinition: AddSystemOrganizationInput;
}
const ADD_ORGANIZATION_MUTATION = `
  mutation Organization($organizationDefinition: AddSystemOrganizationInput!) {
    result: addOrganization(input: $organizationDefinition) {
      ${ORGANIZATION_ENTRY_QUERY_PROPERTIES}
    }
  }
`;

export interface SystemEnvironment {
  sys: SystemProperty;
  id: string;
  customId: string;
  domainKey: string;
  name: string;
}
export interface SystemLocale {
  sys: SystemProperty;
  id: string;
  code: string;
  name: string;
  fallbackCode?: string | null;
}
export interface SystemSpace {
  sys: SystemProperty;
  id: string;
  uuid: string;
  domainKey: string;
  name: string;

  replicationGroup: string;

  defaultEnvironment: SystemEnvironment;
  environments: SystemEnvironment[];

  defaultLocale: SystemLocale;
  locales: SystemLocale[];
}
export interface SystemSpaceEntryQueryVariables {
  organizationId: string;
  spaceUuid: string;
}
const SPACE_ENTRY_QUERY_PROPERTIES = `
  ${SYSTEM_PROPERTY_SUB_QUERY}

  id
  uuid
  domainKey
  name
  replicationGroup

  defaultEnvironment {
    ${SYSTEM_PROPERTY_SUB_QUERY}

    id
    customId
    name
    domainKey
  }

  environments {
    ${SYSTEM_PROPERTY_SUB_QUERY}

    id
    customId
    name
    domainKey
  }

  defaultLocale {
    ${SYSTEM_PROPERTY_SUB_QUERY}

    id
    code
    name
    fallbackCode
  }

  locales {
    ${SYSTEM_PROPERTY_SUB_QUERY}

    id
    code
    name
    fallbackCode
  }
`;
const SPACE_ENTRY_QUERY = `
query getSpace($organizationId: String!, $spaceUuid: String!) {
  result: space(organizationId: $organizationId, uuid: $spaceUuid) {
    ${SPACE_ENTRY_QUERY_PROPERTIES}
  }
}
`;

export interface AddSystemLocaleInput {
  id?: string | null;
  code: string;
  uuid?: string | null;
  name: string;
  description?: string | null;
  isPublished?: boolean | null;
  fallbackCode?: string | null;
  searchLanguage?: string | null;
}
export interface AddSystemEnvironmentInput {
  id?: string | null;
  customId: string;
  uuid?: string | null;
  name: string;
  description?: string | null;
  isPublished?: boolean | null;
  featureConfig?: SystemFeatureConfig | null;
  publicApiPermissions?: PublicApiPermissionConfig | null;
  localeId?: string | null;
  localeCode?: string | null;
}
export interface AddSystemSpaceInput {
  id?: string | null;
  customId?: string | null;
  organizationId: string;
  uuid: string;
  name: string;
  description?: string | null;
  isPublished?: boolean | null;
  replicationGroup: string;
  defaultLocaleCode: string;
  defaultEnvironmentCustomId: string;
  featureConfig?: SystemFeatureConfig | null;
  locales: AddSystemLocaleInput[];
  environments: AddSystemEnvironmentInput[];
}
interface AddSystemSpaceMutationVariables {
  spaceDefinition: AddSystemSpaceInput;
}
const ADD_SPACE_MUTATION = `
  mutation Space($spaceDefinition: AddSystemSpaceInput!) {
    result: addSpace(input: $spaceDefinition) {
      ${SPACE_ENTRY_QUERY_PROPERTIES}
    }
  }
`;

export interface SystemClient {
  sys: SystemProperty;
  id: string;
  name: string;
  customId?: string | null;
  description?: string | null;
  uuid?: string | null;
}
export interface SystemClientCollectionQueryVariables {}
const CLIENT_COLLECTION_QUERY = `
query getClients {
  result: clients {
    ${SYSTEM_PROPERTY_SUB_QUERY}

    id
    customId
    uuid

    name
    description
  }
}
`;
export interface SystemClientEntryQueryVariables {
  clientId: string;
}
const CLIENT_ENTRY_QUERY = `
query getClient($clientId: String!) {
  result: client(id: $clientId) {
    ${SYSTEM_PROPERTY_SUB_QUERY}

    id
    customId
    uuid

    name
    description
  }
}
`;
const CLIENT_SECRET_QUERY = `
query getClient($clientId: String!) {
  result: client(id: $clientId) {
    secret
  }
}
`;

export interface SystemContentTypeProperty {
  sys: SystemProperty;

  id: string;
  customId: string;
  uuid?: string | null;
  machineName: string;

  type: string;

  name: string;

  isPublished: boolean;

  isLocalized: boolean;
  isArray: boolean;
  isRequired: boolean;

  isItemRequired?: boolean | null;
  isLink?: boolean | null;
  isParentLink?: boolean | null;
  isBig?: boolean | null;

  allowedTypes?: string[] | null;
  description?: string | null;
  searchFormat?: SystemSearchIndexFormat | null;
}
export interface SystemContentType {
  sys: SystemProperty;

  id: string;
  customId: string;
  uuid?: string | null;
  machineName: string;

  name: string;
  description?: string | null;

  isPublished: boolean;

  properties: SystemContentTypeProperty[];

  isInline?: boolean | null;
  isIndependent?: boolean | null;
  isAsset?: boolean | null;
  isTaxonomy?: boolean | null;

  parent?: {
    id?: string | null;
    customId?: string | null;
    uuid?: string | null;
    machineName?: string | null;
  } | null;
}
export enum SystemContentTypePropertyType {
  String = 'String',
  Float = 'Float',
  Int = 'Int',
  Boolean = 'Boolean',
  Date = 'Date',
  JSON = 'JSON',
  AnyEntry = 'AnyEntry',
}
export enum SystemSearchIndexFormat {
  HTML = 'html',
  Markdown = 'markdown',
  Plain = 'plain',
  Auto = 'auto',
}
export interface AddSystemContentTypePropertyInput {
  id?: string | null;
  customId: string;
  uuid?: string | null;
  name: string;
  isPublished?: boolean | null;
  machineName: string;
  isArray: boolean;
  isRequired: boolean;
  isItemRequired?: boolean | null;
  isLink?: boolean | null;
  isParentLink?: boolean | null;
  isLocalized: boolean;
  isBig: boolean;
  type: string;
  allowedTypes?: string[] | null;
  description?: string | null;
  searchFormat?: SystemSearchIndexFormat | null;
}
export interface AddSystemContentTypeInput {
  spaceId: string;
  id?: string | null;
  uuid?: string | null;
  customId: string;
  name: string;
  isPublished?: boolean | null;
  machineName: string;
  properties: AddSystemContentTypePropertyInput[];
  parentId?: string | null;
  parentCustomId?: string | null;
  parentMachineName?: string | null;
  isInline?: boolean | null;
  isIndependent?: boolean | null;
  isAsset?: boolean | null;
  isTaxonomy?: boolean | null;
  description?: string | null;
  environmentIds?: string[] | null;
  environmentCustomIds?: string[] | null;
  localeId?: string | null;
  localeCode?: string | null;
}
interface AddSystemContentTypeMutationVariables {
  contentTypeDefinition: AddSystemContentTypeInput;
}
const CONTENT_TYPE_PROPERTY_ENTRY_QUERY_PROPERTIES = `
  ${SYSTEM_PROPERTY_SUB_QUERY}

  id
  customId
  uuid
  machineName

  type

  name

  isPublished

  isLocalized
  isArray
  isRequired

  isItemRequired
  isLink
  isParentLink
  isBig

  allowedTypes
  description
  searchFormat
`;
const CONTENT_TYPE_ENTRY_QUERY_PROPERTIES = `
  ${SYSTEM_PROPERTY_SUB_QUERY}

  id
  customId
  uuid
  machineName

  name
  description

  isPublished

  properties {
    ${CONTENT_TYPE_PROPERTY_ENTRY_QUERY_PROPERTIES}
  }

  isInline
  isIndependent
  isAsset
  isTaxonomy

  parent {
    id
    customId
    uuid
    machineName
  }
`;
const ADD_CONTENT_TYPE_MUTATION = `
  mutation ContentType($contentTypeDefinition: AddSystemContentTypeInput!) {
    result: addContentType(input: $contentTypeDefinition) {
      ${CONTENT_TYPE_ENTRY_QUERY_PROPERTIES}
    }
  }
`;

function getContentTypeInput(
  contentType: SystemContentType,
  space: SystemSpace,
  environments: SystemEnvironment[]
): AddSystemContentTypeInput {
  return {
    customId: contentType.customId,
    name: contentType.name,
    properties: contentType.properties.map((property) => ({
      customId: property.customId,
      machineName: property.machineName,
      isArray: property.isArray,
      isBig: property.isBig ?? false,
      isLocalized: property.isLocalized,
      isRequired: property.isRequired,
      name: property.name,
      type: property.type,
      allowedTypes: property.allowedTypes ?? null,
      description: property.description ?? null,
      isItemRequired: property.isItemRequired ?? false,
      isLink: property.isLink ?? false,
      isParentLink: property.isParentLink ?? false,
      isPublished: true,
      searchFormat: property.searchFormat ?? null,
    })),
    machineName: contentType.machineName,
    description: contentType.description ?? null,
    spaceId: space.id,
    environmentIds: environments.map((c) => c.id),
    localeId: space.defaultLocale.id,
    isAsset: contentType.isAsset ?? false,
    isIndependent: contentType.isIndependent ?? false,
    isInline: contentType.isInline ?? false,
    isPublished: true,
    isTaxonomy: contentType.isTaxonomy ?? false,
    parentId: contentType.parent?.id ?? null,
  };
}

export class PublisherClient {
  constructor(
    private readonly options: {
      baseUrl: string;
      accessToken: string;
      version: string | 'latest';
    }
  ) {}

  query<ResponseType extends {}, Variables extends Record<string, any>, Name extends string = 'result'>(
    {
      query,
      variables,
    }: {
      query: string;
      variables: Variables;
    },
    returnKey: Name
  ): Promise<ResponseType>;
  query<ResponseType extends {}, Variables extends Record<string, any>, Name extends string = 'result'>({
    query,
    variables,
  }: {
    query: string;
    variables: Variables;
  }): Promise<{ [key in Name]: ResponseType }>;
  async query<ResponseType extends {}, Variables extends Record<string, any>, Name extends string = 'result'>(
    {
      query,
      variables,
    }: {
      query: string;
      variables: Variables;
    },
    returnKey?: Name
  ): Promise<any> {
    const response = await fetch(`${this.options.baseUrl}/${this.options.version}/graphql`, {
      headers: {
        Authorization: `Bearer ${this.options.accessToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        query,
        variables,
      }),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const responseBody = (await response.json()) as any;

    responseBody.errors && console.log(responseBody.errors);

    return returnKey ? responseBody.data?.[returnKey] : responseBody.data;
  }

  async createOrganization(organizationDefinition: AddSystemOrganizationInput): Promise<SystemOrganization | null> {
    return this.query<SystemOrganization, AddSystemOrganizationMutationVariables>(
      {
        query: ADD_ORGANIZATION_MUTATION,
        variables: {
          organizationDefinition,
        },
      },
      'result'
    );
  }

  async getOrganization(organizationUuid: Uuid): Promise<SystemOrganization | null> {
    return this.query<SystemOrganization, SystemOrganizationEntryQueryVariables>(
      {
        query: ORGANIZATION_ENTRY_QUERY,
        variables: {
          organizationUuid,
        },
      },
      'result'
    );
  }

  async createSpace(spaceDefinition: AddSystemSpaceInput): Promise<SystemSpace | null> {
    return this.query<SystemSpace, AddSystemSpaceMutationVariables>(
      {
        query: ADD_SPACE_MUTATION,
        variables: {
          spaceDefinition,
        },
      },
      'result'
    );
  }

  async getSpace(organizationId: string, spaceUuid: Uuid): Promise<SystemSpace | null> {
    return this.query<SystemSpace, SystemSpaceEntryQueryVariables>(
      {
        query: SPACE_ENTRY_QUERY,
        variables: {
          organizationId,
          spaceUuid,
        },
      },
      'result'
    );
  }

  async getClients(): Promise<SystemClient[]> {
    return this.query<SystemClient[], SystemClientCollectionQueryVariables>(
      {
        query: CLIENT_COLLECTION_QUERY,
        variables: {},
      },
      'result'
    );
  }

  async getClient(clientId: string): Promise<SystemClient | null> {
    return this.query<SystemClient, SystemClientEntryQueryVariables>(
      {
        query: CLIENT_ENTRY_QUERY,
        variables: {
          clientId,
        },
      },
      'result'
    );
  }

  async getClientSecret(clientId: string): Promise<string | null> {
    const result = await this.query<{ secret: string }, SystemClientEntryQueryVariables>(
      {
        query: CLIENT_SECRET_QUERY,
        variables: {
          clientId,
        },
      },
      'result'
    );

    return result.secret;
  }

  async createContentType(contentTypeDefinition: AddSystemContentTypeInput): Promise<SystemContentType | null> {
    return this.query<SystemContentType, AddSystemContentTypeMutationVariables>(
      {
        query: ADD_CONTENT_TYPE_MUTATION,
        variables: {
          contentTypeDefinition,
        },
      },
      'result'
    );
  }

  async addContentTypeProperty(
    space: SystemSpace,
    environments: SystemEnvironment[],
    contentType: SystemContentType,
    contentTypeProperty: AddSystemContentTypePropertyInput
  ): Promise<SystemContentType | null> {
    const contentTypeDefinition = getContentTypeInput(contentType, space, environments);
    contentTypeDefinition.properties.push(contentTypeProperty);
    return this.query<SystemContentType, AddSystemContentTypeMutationVariables>(
      {
        query: ADD_CONTENT_TYPE_MUTATION,
        variables: {
          contentTypeDefinition,
        },
      },
      'result'
    );
  }
}
