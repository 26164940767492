import React, { useEffect, useMemo, useState } from 'react';
import { IAppContextProp, withAppContext } from '../../../../common/contexts/AppContext';
import { ILocationProp, INavigateProp, withLocationAndNavigate, withParams } from '../../../RouterHelper';
import { ContentCloudPageLayout } from '../Layouts/ContentCloudPageLayout';
import { ContentEntry, ContentTypeEntry, ExternalEntryLinkType, LocaleEntry, RestClient } from '../RestClient';
import { ContentCloudComponentProps, withContentCloud } from '../WithContentCloud';
import { CONTENT_CLOUD_API_VERSION, getContentCloudSatelliteUrl, loadAllContentTypes } from '../content-cloud-helper';
import { Permission } from '../shared-permissions';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { CopyToClipboardButton, LeftRightContainer, LoadingBar } from '@edgebox/react-components';
import { ContentEntryProperties } from './Components/ContentEntryProperties';
import { HeadlineWithBreadcrumbNavigation } from '../../../../common/components/BreadcrumbNavigation';
import { EntryPublishedBadge } from '../Components/EntryPublishedBadge';
import { EntryRelativeDate } from '../Components/EntryRelativeDate';
import { Link } from 'react-router-dom';
import { SanitizeHtml } from '../SanitizeHtml';
import { ContentCloudDetailsPageLayout } from '../Layouts/ContentCloudDetailsPageLayout';

interface Props extends JSX.IntrinsicAttributes, IAppContextProp, INavigateProp, ILocationProp, ContentCloudComponentProps {
  params: {
    project: string;
    content: string;
    tab: string;
  };
}

function ContentEntryPageComponent({ contentCloudData, params, location, navigate }: Props) {
  const { accessToken, contentCloud, environment, space } = contentCloudData ?? {};

  const client = useMemo(
    () =>
      contentCloud &&
      accessToken &&
      space &&
      environment &&
      new RestClient({
        accessToken,
        baseUrl: getContentCloudSatelliteUrl(contentCloud, {
          api: 'rest',
          environmentSubdomain: `${space.domainKey}-${environment.domainKey}`,
          service: 'live',
          version: CONTENT_CLOUD_API_VERSION,
        }),
        spaceDomainKey: space.domainKey,
      }),
    [accessToken, space, environment, contentCloud]
  );

  const [sidebarTab, setSidebarTab] = useState('general');
  const [contentTab, _setContentTab] = useState(params.tab);
  const setContentTab = useMemo(
    () => (tab: string) => {
      _setContentTab(tab);
      navigate(`/projects/${params.project}/content-cloud/content/${params.content}/${tab}${location.search}`);
    },
    [navigate, _setContentTab, params, location]
  );

  const [contentTypes, setContentTypes] = useState<ContentTypeEntry[] | null>(null);
  useEffect(
    () => (client ? !!loadAllContentTypes(client).then((contentTypes) => setContentTypes(contentTypes)) : true) && void 0,
    [client]
  );

  const search = new URLSearchParams(location.search);
  const locale = search.get('locale');
  const [contentEntry, setContentEntry] = useState<ContentEntry | null>(null);
  useEffect(() => {
    setContentEntry(null);

    client &&
      client
        .contentEntry({ id: params.content, include: 1, embed: 10, ...(locale ? { locale } : {}) })
        .then((contentEntry) => setContentEntry(contentEntry));
  }, [client, params.content, locale]);

  const json = useMemo(() => JSON.stringify(contentEntry, null, 2), [contentEntry]);

  const source = contentEntry?.sys.externalLinks?.find((c) => c.linkType === ExternalEntryLinkType.Source);

  const contentType = contentTypes?.find((c) => c.id === contentEntry?.sys.contentType?.sys.id);

  return (
    <ContentCloudPageLayout>
      <ContentCloudDetailsPageLayout
        pageHeader={
          <HeadlineWithBreadcrumbNavigation className="ms-2" names={{ [params.content]: contentEntry?.sys.name ?? '...' }}>
            {contentType?.name} {contentEntry?.sys.name ?? '...'}
          </HeadlineWithBreadcrumbNavigation>
        }
        sidebar={
          <Tabs
            id={'sidebar-tabs'}
            className={'mb-3'}
            activeKey={sidebarTab}
            onSelect={(k: string | null) => setSidebarTab(k ?? 'general')}
          >
            <Tab eventKey={'general'} title={'General'}>
              <div>
                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Status</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span className="text-muted">Current</span>}
                    right={contentEntry && <EntryPublishedBadge sys={contentEntry.sys} />}
                  />
                </div>
                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Publication</div>
                  {contentEntry && !contentEntry.sys.isPublished && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Draft saved at'}
                      right={<EntryRelativeDate date={contentEntry.sys.versionCreatedAt} />}
                    />
                  )}
                  {contentEntry && contentEntry.sys.publishedAt && (
                    <LeftRightContainer
                      className="pt-2"
                      left={'Published at'}
                      right={<EntryRelativeDate date={contentEntry.sys.publishedAt} />}
                    />
                  )}
                  {contentEntry &&
                    contentEntry.sys.firstPublishedAt &&
                    contentEntry.sys.firstPublishedAt !== contentEntry.sys.versionCreatedAt && (
                      <LeftRightContainer
                        className="pt-2"
                        left={'First published at'}
                        right={<EntryRelativeDate date={contentEntry.sys.firstPublishedAt} />}
                      />
                    )}
                </div>
              </div>
            </Tab>
            <Tab eventKey={'source'} title={'Source'}>
              <div>
                {source ? (
                  <div className="mb-4">
                    <div className="small fw-bold border-bottom text-muted pb-1">Canonical URL</div>
                    {source.canonicalUrl ? (
                      <Link to={source.canonicalUrl} target="_blank" className="text-truncate d-block">
                        {source.domain}
                      </Link>
                    ) : (
                      <em>Not provided.</em>
                    )}
                  </div>
                ) : (
                  <Alert variant="light">This entry doesn't include any source information.</Alert>
                )}
              </div>
            </Tab>
            {/*<Tab eventKey={'links'} title={'Links'}>
                    <div>Links</div>
                  </Tab>*/}
            <Tab eventKey={'info'} title={'Info'}>
              <div>
                {contentType ? (
                  <div className="mb-4">
                    <LeftRightContainer
                      className="pt-2 pb-1 border-bottom"
                      left={<span className="small fw-bold text-muted">Content Type</span>}
                      right={<Link to={`/content-cloud/content-model/${contentType.id}`}>View</Link>}
                    />

                    <LeftRightContainer className="pt-2" left={<span>Name</span>} right={<span>{contentType.name}</span>} />

                    <LeftRightContainer className="pt-2" left={<span>Machine name</span>} right={<span>{contentType.machineName}</span>} />

                    <LeftRightContainer className="pt-2" left={<span>ID</span>} right={<span>{contentType.customId}</span>} />

                    {contentType?.description && (
                      <div className="small text-muted pb-1">
                        <SanitizeHtml>{contentType.description}</SanitizeHtml>
                      </div>
                    )}
                  </div>
                ) : (
                  <LoadingBar />
                )}

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Entry</div>
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>ID</span>}
                    right={
                      <span>
                        {contentEntry?.sys.id}{' '}
                        <CopyToClipboardButton name="copy-entry-id" text={contentEntry?.sys.id ?? '...'} buttonText="" buttonOnly />
                      </span>
                    }
                  />
                  {contentEntry && contentEntry.sys.slug && (
                    <LeftRightContainer
                      className="pt-2"
                      left={<span className="pe-1">Slug</span>}
                      right={
                        <div className="d-flex">
                          <div className="flex-grow-1 flex-shrink-1 text-truncate pe-2">{contentEntry.sys.slug}</div>
                          <div className="flex-grow-0 flex-shrink-0">
                            <CopyToClipboardButton name="copy-entry-id" text={contentEntry.sys.slug} buttonText="" buttonOnly />
                          </div>
                        </div>
                      }
                    />
                  )}
                </div>

                <div className="mb-4">
                  <div className="small fw-bold border-bottom text-muted pb-1">Version</div>
                  <LeftRightContainer className="pt-2" left={<span>Entry</span>} right={<span>{contentEntry?.sys.entryVersion}</span>} />
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Localization</span>}
                    right={<span>{contentEntry?.sys.localizationVersion}</span>}
                  />
                  <LeftRightContainer
                    className="pt-2"
                    left={<span>Version ID</span>}
                    right={
                      <CopyToClipboardButton
                        name="copy-version-id"
                        text={contentEntry?.sys.versionId ?? '...'}
                        buttonText=" Copy"
                        buttonOnly
                      />
                    }
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        }
      >
        <Tabs id={'content-tabs'} className={'mb-3'} activeKey={contentTab} onSelect={(k: string | null) => setContentTab(k ?? 'content')}>
          <Tab eventKey={'content'} title={'Content'}>
            {contentEntry && contentTypes ? (
              <ContentEntryProperties
                allContentTypes={contentTypes}
                contentEntry={contentEntry}
                locale={
                  (locale ?? contentEntry.sys.locale!.sys.type === 'Link')
                    ? contentEntry.sys.locale!.sys.id
                    : ((contentEntry.sys.locale as Partial<LocaleEntry>).code ?? '')
                }
              />
            ) : (
              <LoadingBar />
            )}
          </Tab>
          {/*<Tab eventKey={'links'} title={'Links'}>
                    Links
                  </Tab>
                  <Tab eventKey={'tags'} title={'Tags'}>
                    Tags
                  </Tab>
                  <Tab eventKey={'media'} title={'Media'}>
                    Media
                  </Tab>*/}
          <Tab eventKey={'json'} title={'JSON Preview'}>
            <code className="border rounded border-light p-2 d-block mx-2 mb-2 position-relative">
              <div className="position-absolute" style={{ top: '10px', right: '10px' }}>
                <CopyToClipboardButton name="copy-json" buttonText=" Copy" text={json} buttonOnly />
              </div>
              <pre>{json}</pre>
            </code>
          </Tab>
        </Tabs>
      </ContentCloudDetailsPageLayout>
    </ContentCloudPageLayout>
  );
}

export const ContentEntryPage = withParams<{}, { site: string; tab: string }>(
  withLocationAndNavigate<any>(
    withAppContext<any>(
      withContentCloud(ContentEntryPageComponent, [
        Permission.CONTENT_READ,
        Permission.SPACE_READ,
        Permission.CONTENT_TYPE_READ,
        Permission.PREVIEW,
        Permission.EXTERNAL_LINK_READ,
      ])
    )
  )
);
