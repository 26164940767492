import { BooleanProperty, EnumProperty, NameProperty, NestedProperty } from '@edgebox/data-definition-kit';
import {
  EditMode,
  EntityContext,
  EntityForm,
  EntityFormRow,
  entityFormWithValidation,
  IEntityFormProps,
  IEntityFormState,
} from '../../../../../common';
import {
  AddSystemContentTypeInput,
  AddSystemContentTypePropertyInput,
  SystemContentType,
  SystemContentTypePropertyType,
} from '../../PublisherClient';
import React, { PropsWithChildren, useMemo } from 'react';
import { FormField } from '@edgebox/react-components';

class AddContentTypeProperty {
  constructor(set?: AddContentTypeProperty) {
    if (set) {
      Object.assign(this, set);
    }
  }

  @NameProperty()
  name!: string;

  @NameProperty()
  machineName!: string;

  @NameProperty()
  customId!: string;

  @BooleanProperty(true)
  isArray!: boolean;

  @BooleanProperty(true)
  isRequired!: boolean;

  @BooleanProperty(true)
  isBig!: boolean;

  @EnumProperty(true, SystemContentTypePropertyType, 'SystemContentTypePropertyType')
  type!: SystemContentTypePropertyType;
}

class AddContentType {
  constructor(set?: AddContentType) {
    if (set) {
      Object.assign(this, set);
    }
  }

  @NameProperty()
  name!: string;

  @NameProperty()
  machineName!: string;

  @NameProperty()
  customId!: string;

  @NestedProperty(true, () => AddContentTypeProperty)
  properties!: AddContentTypeProperty[];
}

interface AddContentTypeFormProps extends IEntityFormProps<AddContentType, AddContentType>, PropsWithChildren {
  spaceId: string;
  onSave: (draft: AddSystemContentTypeInput) => Promise<void>;
}
interface AddContentTypeFormState extends IEntityFormState<AddContentType> {}
const ContentTypeFormWithValidation = entityFormWithValidation<AddContentType, AddContentType>(AddContentType, AddContentType);
export class AddContentTypeInnerForm extends EntityForm<AddContentType, AddContentType, AddContentTypeFormProps, AddContentTypeFormState> {
  constructor(props: AddContentTypeFormProps) {
    super(props, {
      edit: EditMode.Full,
    });
  }

  async load() {
    return {};
  }

  render() {
    return (
      <>
        <EntityContext.Provider value={this.entityContext}>
          <ContentTypeFormWithValidation
            {...this.formProperties()}
            apiComponent={this}
            initialValues={this.props.entity}
            labels={'above'}
            onSubmit={async (entity) => {
              this.setState({
                extendProperties: [],
              });

              const machineName = entity.name
                // E.g. "my user data 123" => "My User Data 123"
                .replace(/(\W)([a-z])/g, (_, space, letter) => space + letter.toLocaleUpperCase())
                // E.g. "My User Data 123" => "MyUserData123"
                .replace(/[^a-zA-Z0-9]+/g, '')
                // E.g. "123 My User Data" => "MyUserData"
                .replace(/^[^a-zA-Z]+/g, '');
              const customId = machineName.replace(
                /(^|[^A-Z])([A-Z])/g,
                (_, start, letter) => `${start ? `${start}_` : ''}${letter.toLowerCase()}`
              );

              const draft: AddSystemContentTypeInput = {
                spaceId: this.props.spaceId,
                ...entity,
                machineName: `${machineName}ContentUserData`,
                customId: `content_user_data.${customId}`,
                properties: entity.properties.map((property) => ({
                  ...property,
                  isLocalized: false,
                })),
              };

              await this.props.onSave(draft);
            }}
          >
            {({ values, setValue, errors }) => (
              <>
                <EntityFormRow<AddContentType>
                  name={'name'}
                  label={'Name'}
                  edit={() => <FormField<AddContentType> type={'text'} name={'name'} labelPlacement={'none'} noRow />}
                  view={values.name ? () => values.name : undefined}
                />

                {this.props.children}
              </>
            )}
          </ContentTypeFormWithValidation>
        </EntityContext.Provider>
      </>
    );
  }
}
export function AddContentTypeForm(props: Omit<AddContentTypeFormProps, 'entity'>) {
  const draft = useMemo(() => {
    return new AddContentType({
      customId: 'content_user_data.flags',
      machineName: 'FlagsContentUserData',
      name: 'Flags',
      properties: [],
    });
  }, []);

  return <AddContentTypeInnerForm {...props} entity={draft} />;
}

interface AddContentTypePropertyFormProps extends IEntityFormProps<AddContentTypeProperty, AddContentTypeProperty>, PropsWithChildren {
  contentType: SystemContentType;
  onSave: (draft: AddSystemContentTypePropertyInput) => Promise<void>;
}
interface AddContentTypePropertyFormState extends IEntityFormState<AddContentTypeProperty> {}
const ContentTypePropertyFormWithValidation = entityFormWithValidation<AddContentTypeProperty, AddContentTypeProperty>(
  AddContentTypeProperty,
  AddContentTypeProperty
);
export class AddContentTypePropertyInnerForm extends EntityForm<
  AddContentTypeProperty,
  AddContentTypeProperty,
  AddContentTypePropertyFormProps,
  AddContentTypePropertyFormState
> {
  constructor(props: AddContentTypePropertyFormProps) {
    super(props, {
      edit: EditMode.Full,
    });
  }

  async load() {
    return {};
  }

  render() {
    return (
      <>
        <EntityContext.Provider value={this.entityContext}>
          <ContentTypePropertyFormWithValidation
            {...this.formProperties()}
            apiComponent={this}
            initialValues={this.props.entity}
            labels={'above'}
            onSubmit={async (entity) => {
              this.setState({
                extendProperties: [],
              });

              const machineName = entity.name
                // E.g. "my user data 123" => "My User Data 123"
                .replace(/(\W)([a-z])/g, (_, space, letter) => space + letter.toLocaleUpperCase())
                // E.g. "My User Data 123" => "MyUserData123"
                .replace(/[^a-zA-Z0-9]+/g, '')
                // E.g. "123 My User Data" => "MyUserData"
                .replace(/^[^a-zA-Z]+/g, '');
              const customId = machineName.replace(
                /(^|[^A-Z])([A-Z])/g,
                (_, start, letter) => `${start ? `${start}_` : ''}${letter.toLowerCase()}`
              );

              const draft: AddSystemContentTypePropertyInput = {
                ...entity,
                machineName: `${machineName}ContentUserData`,
                customId: `${this.props.contentType.customId}.${customId}`,
                isLocalized: false,
              };

              await this.props.onSave(draft);
            }}
          >
            {({ values, setValue, errors }) => (
              <>
                <EntityFormRow<AddContentType>
                  name={'name'}
                  label={'Name'}
                  edit={() => <FormField<AddContentType> type={'text'} name={'name'} labelPlacement={'none'} noRow />}
                  view={values.name ? () => values.name : undefined}
                />

                {this.props.children}
              </>
            )}
          </ContentTypePropertyFormWithValidation>
        </EntityContext.Provider>
      </>
    );
  }
}
export function AddContentTypePropertyForm(props: Omit<AddContentTypePropertyFormProps, 'entity'>) {
  const draft = useMemo(() => {
    return new AddContentTypeProperty({
      customId: 'content_user_data.flags.bookmarked',
      machineName: 'bookmarked',
      isArray: false,
      isBig: false,
      isRequired: false,
      name: 'Bookmarked',
      type: SystemContentTypePropertyType.Boolean,
    });
  }, []);

  return <AddContentTypePropertyInnerForm {...props} entity={draft} />;
}
