import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../../common/index';
import { AppContext } from '../../../common/contexts/AppContext';
import { ClientCustomerEntity, ClientProjectEntity, ClientRemoteServiceEntity, IContentSyncApi } from '@edgebox/api-rest-client';
import { SystemEnvironment, SystemOrganization, SystemSpace } from './PublisherClient';
import { Permission } from './shared-permissions';

export interface ContentCloudComponentData {
  contentCloud: ClientRemoteServiceEntity;
  project: ClientProjectEntity;

  organization: SystemOrganization;
  space: SystemSpace;
  environment: SystemEnvironment;

  accessToken: string;
}

export interface ContentCloudComponentProps {
  contentCloudData?: ContentCloudComponentData;
}

const RETRY_INTERVAL_MS = 5_000;

export async function updateAccessToken(
  api: IContentSyncApi,
  { environment, contentCloud, project }: ContentCloudComponentData,
  permissions: Permission[]
) {
  return await api.syndication.remoteServices.getContentCloudRequestToken(contentCloud.id, project.id, permissions, {
    environmentIds: [environment.id],
  });
}

export type ContentCloudComponentDataLoader = {
  loaded: boolean;
  aborted: boolean;
  contentCloudData?: ContentCloudComponentData;
  getContentCloudData: Promise<ContentCloudComponentData>;
  abort: () => void;
  onLoad: (clb: (contentCloudData: ContentCloudComponentData) => void) => void;
};
export function initContentCloudAndRetry(
  api: IContentSyncApi,
  contentCloud: ClientRemoteServiceEntity,
  permissions: Permission[],
  project: ClientProjectEntity,
  customer?: ClientCustomerEntity
): ContentCloudComponentDataLoader {
  const callbacks: ((contentCloudData: ContentCloudComponentData) => void)[] = [];
  const result: ContentCloudComponentDataLoader = {
    loaded: false,
    aborted: false,
    getContentCloudData: new Promise<ContentCloudComponentData>(async (resolve) => {
      let data: ContentCloudComponentData | undefined;
      let aborted = false;

      do {
        data = await initContentCloud(api, contentCloud, permissions, project);

        if (aborted) {
          return;
        }

        if (!data) {
          await new Promise((resolve) => setTimeout(resolve, RETRY_INTERVAL_MS));
        }
      } while (!data);

      resolve(data);

      for (const clb of callbacks) {
        clb(data);
      }
    }),
    abort: () => {
      result.aborted = true;
    },
    onLoad: (clb) => {
      callbacks.push(clb);
    },
  };

  return result;
}

export async function initContentCloud(
  api: IContentSyncApi,
  contentCloud: ClientRemoteServiceEntity,
  permissions: Permission[],
  project: ClientProjectEntity
): Promise<ContentCloudComponentData | undefined> {
  const { jwt, organization, space } = await api.syndication.remoteServices.getContentCloudSpace(contentCloud.id, project.id, permissions);

  const environment = space.defaultEnvironment;

  return {
    contentCloud,
    project,

    organization,
    space,
    environment,

    accessToken: jwt,
  };
}

export function WithContentCloud(props: {
  children: (data?: ContentCloudComponentData) => React.ReactNode;
  permissions: Permission[];
  project?: ClientProjectEntity;
}) {
  const api = useContext(ApiContext);
  const appContext = useContext(AppContext);

  let { customer, project } = appContext;
  if (props.project) {
    project = props.project;
  }

  const [contentCloudData, setContentCloudData] = useState<ContentCloudComponentDataLoader | undefined>();

  const permissionString = props.permissions.sort().join(',');
  useEffect(() => {
    if (!customer || !project || !api || !project.syncCore) {
      return;
    }

    project.syncCore?.get().then(async (contentCloud) => {
      if (!contentCloud) {
        return;
      }

      const data = await initContentCloudAndRetry(api, contentCloud, permissionString.split(',') as any, project, customer);
      setContentCloudData(data);

      data.onLoad((contentCloudData) => {
        setContentCloudData({
          ...data,
          contentCloudData,
        });
      });
    });
  }, [api, customer, project, permissionString]);

  return props.children(contentCloudData?.contentCloudData);
}

export function withContentCloud<P extends ContentCloudComponentProps>(
  Component: React.FunctionComponent<P>,
  permissions: Permission[]
): React.FunctionComponent<Omit<P, keyof ContentCloudComponentProps>> {
  return function ComponentWithContentCloud(otherProps: Omit<P, keyof ContentCloudComponentProps>) {
    return (
      <WithContentCloud permissions={permissions}>
        {(data?: ContentCloudComponentData) => (<Component {...({ ...otherProps, contentCloudData: data } as P)} />) as React.ReactNode}
      </WithContentCloud>
    );
  };
}
