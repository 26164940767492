import { IPagedListResponse } from '@edgebox/data-definition-kit';
import { EntitySelector, IEntitySelectorProps, IEntitySelectorState, ITextSearchFilter } from '../../../../common';
import { PublisherClient, SystemEnvironment } from '../PublisherClient';
import { ContentCloudComponentData, ContentCloudComponentProps, WithContentCloud } from '../WithContentCloud';
import { Permission } from '../shared-permissions';
import React from 'react';
import { LoadingBar } from '@edgebox/react-components';
import { CONTENT_CLOUD_API_VERSION } from '../content-cloud-helper';
import { ClientProjectEntity } from '@edgebox/api-rest-client';

interface Props extends IEntitySelectorProps<SystemEnvironment>, ContentCloudComponentProps {
  contentCloudData: ContentCloudComponentData;
  contentCloudProject?: ClientProjectEntity;
}
interface State extends IEntitySelectorState<SystemEnvironment> {
  publisherClient?: PublisherClient;
}

class EnvironmentSelectorComponent extends EntitySelector<SystemEnvironment, Props, State> {
  async load(): Promise<Partial<State>> {
    return {
      ...(await super.load()),
      publisherClient: new PublisherClient({
        accessToken: this.props.contentCloudData.accessToken,
        baseUrl: this.props.contentCloudData.contentCloud.baseUrl,
        version: CONTENT_CLOUD_API_VERSION,
      }),
    };
  }

  async search(page: number, filter?: ITextSearchFilter | undefined): Promise<IPagedListResponse<SystemEnvironment>> {
    let items = this.props.contentCloudData.space.environments;

    if (filter?.search) {
      const search = filter.search;
      items = items.filter((c) => c.id === search || c.customId === search || c.name.toLowerCase().includes(search.toLowerCase()));
    }

    return {
      items,
      itemsPerPage: items.length,
      numberOfPages: 1,
      page: 0,
      totalNumberOfItems: items.length,
    };
  }

  renderItemAsText(entity: SystemEnvironment): string {
    return entity.name;
  }

  renderItem(entity: SystemEnvironment): React.ReactNode {
    return entity.name;
  }

  renderEmpty(): React.ReactNode {
    return 'None';
  }

  renderCurrentValue(selected: SystemEnvironment): React.ReactNode {
    return selected.name;
  }
}

export function EnvironmentSelector(props: Omit<Props, keyof ContentCloudComponentProps>) {
  return (
    <WithContentCloud permissions={[Permission.SPACE_READ]} project={props.contentCloudProject}>
      {(contentCloudData) =>
        contentCloudData ? <EnvironmentSelectorComponent {...props} contentCloudData={contentCloudData} /> : <LoadingBar />
      }
    </WithContentCloud>
  );
}
